import React from 'react';
import { css, cx } from 'emotion';
import { theme } from 'Components/AppTheme';
import { capitals } from 'Helpers/CommonStyle';

import Configure from './Configure';

const ConfigureExplore = ({ setModel }) => {
  return (
    <>
      <div className={css`
        padding: 0 ${theme.space.ms} ${theme.space.ms};
        border-bottom: 1px solid ${theme.color.greyDark};

        ${theme.mediaQuery.md} {
          padding: ${theme.space.ms} ${theme.space.l};
          border-top: 1px solid ${theme.color.greyDark};
        }
      `}>
        <p className={cx(capitals, css`
            color: ${theme.color.grey};
            `)}
        >
          Configure & Explore
        </p>
      </div>
      <Configure />
    </>
  );
};

export default ConfigureExplore;
