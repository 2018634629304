import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { theme } from 'Components/AppTheme';
import concreteUrl from 'Assets/concrete-sidebar.png';
import Button from 'Elements/Button';
import { ReactComponent as ArrowIcon } from 'Assets/Arrow.svg';
import { ReactComponent as CloseIcon } from 'Assets/Times.svg';

export const StyledCheckoutPane = styled.div`
  width: 570px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid ${theme.color.greyDark};
  background-color: #000;
  background-image: url(${concreteUrl});
  background-size: cover;
  background-position: center;

  ${theme.mediaQuery.md} {
    width: 100%;
  }
`;

const headerStyle = css`
  padding: ${theme.space.ms};
  background: ${theme.color.greyDark};
  font-weight: bold;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  svg {
    width: 13px;
    height: 13px;
    transition: opacity .2s;
    cursor: pointer;

    &:hover {
      opacity: .75;
    }

    g {
      stroke: #fff;
    }
  }
`;

const paneContentStyle = css`
  margin: 70px 0 54px;
  height: calc(100% - 70px - 54px);
  padding: ${theme.space.ms};
  overflow: scroll;
`;

const CheckoutPane = ({
  heading = '',
  button = {
    text: '',
    disabled: false,
    onClick() {},
  },
  closeCallback = () => {},
  children,
}) => {
  return (
    <StyledCheckoutPane>
      <div className={headerStyle}>
        {heading}
        <CloseIcon onClick={closeCallback}/>
      </div>
      <div className={paneContentStyle}>
        {children}
      </div>
      <Button onClick={button.onClick} disabled={button.disabled} variant="big filled arrowIcon" className={css`
        position: absolute;
        bottom: 0;
        left: 0;
      `}>
        {button.text}
        <ArrowIcon />
      </Button>
    </StyledCheckoutPane>
  );
};

export default CheckoutPane;
