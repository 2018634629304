const emailValidator = require('email-validator');

const validateFormData = (fields, values) => {
  const fieldErrors = {};

  const addError = (name, error) => {
    if (fieldErrors[name]) {
      fieldErrors[name].push(error);
    } else {
      fieldErrors[name] = [error];
    }
  };

  fields.forEach((field) => {
    if (field.required) {
      if (!values[field.name] || values[field.name] === '') {
        addError(field.name, 'This field is required');
      }
    }

    if (field.type === 'email' && !emailValidator.validate(values[field.name])) {
      addError(field.name, 'The e-mail address is invalid');
    }
  });

  return fieldErrors;
};

const formatInteger = (number) => {
  /*
   * This function exists so that we can, for example, comma-separate thousands.
   * However, client did not want that. Sigh.
   * Maybe add it in the future again? `.toLocaleString('en')`
   */
  return parseInt(number, 10);
};

const viewFunctions = (viewer, allIdsToToggle, allIdsToToggleRobot, interiorView, idsToSetAlwaysOffInRobot, setInteriorView) => {
  const THREE = window.THREE

  const ShowWalls = (setInteriorView) => {
    if (!allIdsToToggle.length) return;

    setInteriorView(false);

    allIdsToToggle.forEach((id) => {
        viewer.impl.visibilityManager.show(id);
    });
  }

  const HideWalls = (setInteriorView) => {
    if (!allIdsToToggle.length) return;

    setInteriorView(true);

    allIdsToToggle.forEach((id) => {
        viewer.impl.visibilityManager.hide(id);
    });
  }

  const ShowRobot = () => {
    if (!allIdsToToggleRobot.length) return;

    allIdsToToggleRobot.forEach((id) => {
      viewer.impl.visibilityManager.show(id);
    });

    idsToSetAlwaysOffInRobot.forEach((id) => {
      viewer.hide(id);
    });
  }

  const HideRobot = () => {
    if (!allIdsToToggleRobot.length) return;

    allIdsToToggleRobot.forEach((id) => {
      viewer.impl.visibilityManager.hide(id);
    });

    idsToSetAlwaysOffInRobot.forEach((id) => {
      viewer.hide(id);
    });
  }

  const SetHomeView = (setInteriorView) => {
    if (!allIdsToToggle.length) return;
    if (!allIdsToToggleRobot.length) return;

    HideRobot();

    // if (interiorView) {
    //   HideWalls(setInteriorView)
    // } else {
    //   ShowWalls(setInteriorView);
    // }

    viewer.navigation.setView(new THREE.Vector3(-6000, 2300, -4000), new THREE.Vector3(-4500,1600,-3000));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));
    viewer.navigation.setIsLocked(false);

    return true;
  }

  const SetCamera2 = () => {
    if (!allIdsToToggle.length) return;
    // Skid mounted engine and pump
    //Spark arrestor
    //Shut down valve

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-2111, 1010,-3232), new THREE.Vector3(-1102,  468, -1673));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));
  }

  const SetCamera3 = () => {
    if (!allIdsToToggle.length) return;
    //REVO 3.0 control system
    //Revo Remote
    //Revo Remote pro
    //Power saving (Start/Stop)

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-1972, 200,925), new THREE.Vector3(-51,  -17, 948));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera4 = () => {
    if (!allIdsToToggle.length) return;
    // Pressure and flow

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-2679, 762,-404), new THREE.Vector3(-870,  304, 103));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera5 = () => {
    if (!allIdsToToggle.length) return;
    // Liquid-to-air cooling system
    //Quick start (liquid-to-air cooling system)

    // ShowWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-3302, 964,5071), new THREE.Vector3(-1912,  546, 3794));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }


  const SetCamera6 = () => {
    if (!allIdsToToggle.length) return;
    //  Sound absorbing design

    viewer.navigation.setView(new THREE.Vector3(142, 615,-778), new THREE.Vector3(2066,  422, -825));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }


  const SetCamera7 = () => {
    if (!allIdsToToggle.length) return;
    //Inlet and outlet (Water)
    //USA equipped (1 5/16 connector, camlock inlet)
    //Inlet pressure reducing valve, max 40 bar

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-489, -996,4227), new THREE.Vector3(-941,  -928, 2347));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera8 = () => {
    if (!allIdsToToggle.length) return;
    // Standard 20ft container size
    // Sound absorbing design

    ShowWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-7380, 760,40), new THREE.Vector3(-5450,  542, 39));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera9 = () => {
    if (!allIdsToToggle.length) return;
    // Robot inside Ecosilence
    // Driving ramps for robot

    HideWalls(setInteriorView);
    ShowRobot();
    viewer.navigation.setView(new THREE.Vector3(-5980, 1262,-1974), new THREE.Vector3(-4100,  812, -1908));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera10 = () => {
    if (!allIdsToToggle.length) return;
    // Exhaust silencers (PRO)

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-188, 868,-4291), new THREE.Vector3(733,  1140, -2612));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }


  const SetCamera11 = () => {
    if (!allIdsToToggle.length) return;
    //  Proportional inlet valve

    viewer.navigation.setView(new THREE.Vector3(-109, 576,-1247), new THREE.Vector3(872,  579, 418));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera12 = () => {
    if (!allIdsToToggle.length) return;
    //1 Roll off frame (tänd/släck)
    //2 Air vent protection (tänd/släck)

    ShowWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-4037, 570,8077), new THREE.Vector3(-2907,  540, 6508));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera13 = () => {
    if (!allIdsToToggle.length) return;
    //Lifting device for pump head

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-2430, 958,-1334), new THREE.Vector3(-783,  837, -329));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera14 = () => {
    if (!allIdsToToggle.length) return;
    //Frost protection kit

    HideWalls(setInteriorView);
    HideRobot();
    viewer.navigation.setView(new THREE.Vector3(-1355, 2,-1582), new THREE.Vector3(495,  -353, -1018));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }


  const SetCamera15 = () => {
    if (!allIdsToToggle.length) return;
    //Electric kit 400/230 VAC

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-1823, 167,1310), new THREE.Vector3(102,  -1, 1267));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera16 = () => {
    if (!allIdsToToggle.length) return;
    //Work bench with tools

    HideWalls(setInteriorView);
    HideRobot();
    viewer.navigation.setView(new THREE.Vector3(-1615, 150,-2247), new THREE.Vector3(262,  -139, -1886));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }


  const SetCamera17 = () => {
    if (!allIdsToToggle.length) return;
    //Storage

    viewer.navigation.setView(new THREE.Vector3(584, 806,-2794), new THREE.Vector3(-1081,  303, -1948));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera18 = () => {
    if (!allIdsToToggle.length) return;
    //Outside fuel and AdBlue fill

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-1751, 166,1644), new THREE.Vector3(175,  14, 1679));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }


  const SetCamera19 = () => {
    if (!allIdsToToggle.length) return;
    //Water prefilter
    //Filter differential pressure monitoring

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-2012, 108,-276), new THREE.Vector3(-108,  -230, -295));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera20 = () => {
    if (!allIdsToToggle.length) return;
    //Checkvalve

    viewer.navigation.setView(new THREE.Vector3(-956, 1090,-1111), new THREE.Vector3(116,  775, 466));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera21 = () => {
    if (!allIdsToToggle.length) return;
    //  Extra receiver for connected controls
    //Radio remote

    viewer.navigation.setView(new THREE.Vector3(-575, 713,50), new THREE.Vector3(-1528,  -150, 1494));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }


  const SetCamera22 = () => {
    if (!allIdsToToggle.length) return;
    //  Flashing status light

    viewer.navigation.setView(new THREE.Vector3(-1518, 1200,905), new THREE.Vector3(-316,  585, 2290));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera23 = () => {
    if (!allIdsToToggle.length) return;
    //  Emergency stop box Revo
    //Foot pedal Revo
    //Extension cables

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-1670, 154,1142), new THREE.Vector3(126,  -22, 450));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  const SetCamera24 = () => {
    if (!allIdsToToggle.length) return;
    // Dosing system
    // Kit Dump valve

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-3292, 356,216), new THREE.Vector3(-1366,  187, 185));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }


  const SetCamera25 = () => {
    if (!allIdsToToggle.length) return;
    // Air kit with airgun
    //Diesel heater

    HideWalls(setInteriorView);
    viewer.navigation.setView(new THREE.Vector3(-7380, 760,40), new THREE.Vector3(-5450,  542, 39));
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));

  }

  return {
    SetHomeView,
    SetCamera2,
    SetCamera3,
    SetCamera4,
    SetCamera5,
    SetCamera6,
    SetCamera7,
    SetCamera8,
    SetCamera9,
    SetCamera10,
    SetCamera11,
    SetCamera12,
    SetCamera13,
    SetCamera14,
    SetCamera15,
    SetCamera16,
    SetCamera17,
    SetCamera18,
    SetCamera19,
    SetCamera20,
    SetCamera21,
    SetCamera22,
    SetCamera23,
    SetCamera24,
    SetCamera25,
    HideRobot,
    HideWalls,
  }
};

module.exports = {
  viewFunctions,
  validateFormData,
  formatInteger,
};
