import React, { useContext } from 'react';
import { css } from 'emotion';
import { theme } from 'Components/AppTheme';
import HeaderBar from 'Parts/HeaderBar';
import ThreeDeeViewer from 'Blocks/ThreeDeeViewer';
import StartPage from 'Blocks/StartPage';
import { AppContext } from 'Components/AppContext';

const contentAreaStyle = css`
  position: absolute;
  top: 0;
  left: ${theme.size.sidebarWidth};
  width: calc(100% - ${theme.size.sidebarWidth});
  height: 100%;
  display: flex;
  flex-direction: column;

  ${theme.mediaQuery.md} {
    position: static;
    width: 100%;
    height: auto;
    flex: 0 0 auto;
  }
`;

export default () => {
  const { appState } = useContext(AppContext);
  const {
    model,
    setModel,
    unitSystem,
  } = appState;

  const resetModel = () => {
    setModel(false);
  }

  return (
    <div className={contentAreaStyle}>
      <HeaderBar model={model} resetModel={resetModel} unitSystem={unitSystem} />
      {model ? (
        <ThreeDeeViewer />
      ) : (
        <StartPage />
      )}
    </div>
  );
};
