import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';

const palette = {
  black: '#000',
  white: '#fff',
  alizarinCrimson: '#e1241a',
  roofTerracotta: '#A52E22',
};

const color = {
  black: palette.black,
  white: palette.white,
  greyLight: '#ededed',
  grey: '#808080',
  greyDark: '#282828',

  brand: {
    main: palette.alizarinCrimson,
    mainDark: palette.roofTerracotta,
  },

  accent: palette.alizarinCrimson,
  accentDark: palette.roofTerracotta,

  body: {
    background: palette.black,
    color: palette.white,
  },
};

const fontPalette = {
  berninoSans: 'jaf-bernino-sans, sans-serif',
};

export const typography = {
  size: {
    root: '16px',
    heading1: '2rem',
    heading2: '1.5rem',
    heading3: '1.25rem',
  },
  family: {
    body: fontPalette.berninoSans,
    heading: fontPalette.berninoSans,
  },
  lineHeight: {
    default: '1.4rem',
  },
  spacing: {
    paragraph: '1.25rem',
    headingTop: '2.25rem',
    headingBottom: '1.25rem',
  },
};

const defaultSpaceInt = 16;

const spaceInt = {
  xs: defaultSpaceInt * 0.25,
  s: defaultSpaceInt * 0.5,
  sxs: defaultSpaceInt * 0.5 + defaultSpaceInt * 0.25,
  m: defaultSpaceInt,
  ms: defaultSpaceInt + defaultSpaceInt * 0.5,
  l: defaultSpaceInt * 2,
  lm: defaultSpaceInt * 2 + defaultSpaceInt,
  xl: defaultSpaceInt * 4,
};

const space = {
  xs: `${spaceInt.xs}px`,
  s: `${spaceInt.s}px`,
  sxs: `${spaceInt.sxs}px`,
  m: `${spaceInt.m}px`,
  ms: `${spaceInt.ms}px`,
  l: `${spaceInt.l}px`,
  lm: `${spaceInt.lm}px`,
  xl: `${spaceInt.xl}px`,
};

const size = {
  sidebarWidth: '300px',
};

const deviceSizes = {
  small: '330px',
  smallUp: '331px',
  medium: '767px',
  mediumUp: '768px',
  large: '1024px',
  largeUp: '1025px',
};

const mediaQuery = {
  sd: `@media only screen and (max-width: ${deviceSizes.small})`,
  su: `@media only screen and (min-width: ${deviceSizes.smallUp})`,
  md: `@media only screen and (max-width: ${deviceSizes.medium})`,
  mu: `@media only screen and (min-width: ${deviceSizes.mediumUp})`,
  ld: `@media only screen and (max-width: ${deviceSizes.large})`,
  lu: `@media only screen and (min-width: ${deviceSizes.largeUp})`,
};

export const theme = {
  palette,
  color,
  fontPalette,
  typography,
  defaultSpaceInt,
  spaceInt,
  space,
  size,
  deviceSizes,
  mediaQuery,
};

export const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export const GlobalStyles = () => (
  React.createElement(
    Global, { styles: `
      * {
        margin: 0;
        padding: 0;
      }

      html {
        box-sizing: border-box;
      }

      *, *::before, *::after {
        box-sizing: inherit;
      }

      html, body, #root, .App {
        width: 100%;
        height: 100%;
        position: relative;
      }

      body {
        background: ${color.body.background};
        color: ${color.body.color};
        font: normal ${typography.size.root} ${typography.family.body};
        line-height: ${typography.lineHeight.default};
      }

      h1,
      h2,
      h3,
      h4
      {
        font-family: ${typography.family.heading};
        font-weight: 400;

        &:not(:last-child) {
          margin: 0 0 ${typography.spacing.headingBottom};
        }

        &:not(:first-of-type) {
          margin-top: ${typography.spacing.headingTop};
        }
      }

      h1 {
        font-size: ${typography.size.heading1};
        line-height: 2.5rem;
      }

      h3 {
        font-size: ${typography.size.heading3};
        line-height: 1.6rem;
      }

      p {
        &:not(:last-child) {
          margin-bottom: ${typography.spacing.paragraph};
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      .grecaptcha-badge {
        visibility: hidden;
      }
    ` },
    null,
  )
);
