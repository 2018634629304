import React, {useReducer} from 'react';
import { css, cx } from 'emotion';
import { theme } from 'Components/AppTheme';
import { capitals } from 'Helpers/CommonStyle';
import utils from '@root/src/utils.js';

const modelInfoHolder = css`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
`;

const modelInfoContent = css`
  display: flex;
  list-style: none;

  > li {
    &:not(:last-of-type) {
      margin-right: ${theme.space.m};

      ${theme.mediaQuery.sd} {
        margin-right: ${theme.space.s};
      }
    }

    span {
      color: ${theme.color.grey};
    }
  }
`;

export default ({model, unitSystem}) => {

  const activeUnit = {
    bar: unitSystem === 'metric',
    ltr: unitSystem === 'metric'
  };

  const reducer = (state, action) => {
    switch (action.actionType) {
      case 'TOGGLE_BAR':
        return {
          ...state,
          bar: !state.bar,
        };

      case 'TOGGLE_LTR':
      return {
          ...state,
          ltr: !state.ltr,
        };

      default:
        return {
          ...state,
        };
    }
  }

  const [state, dispatch] = useReducer(reducer, activeUnit);

  return (
    <div className={cx(capitals, modelInfoHolder)}>
      <ul className={modelInfoContent}>
        <li>{utils.formatInteger(model.force.n)} <span>N</span></li>
        <li>{model.motor.hp} <span>hp</span></li>
        <li
          className={css`
            cursor: pointer;
          `}
          onClick={() => {dispatch({actionType: 'TOGGLE_BAR'})}}
        >
          {utils.formatInteger((state.bar ? model.pressure.bar : model.pressure.psi))} <span>{state.bar ? 'bar' : 'psi'}</span>
        </li>
        <li
          className={css`
            cursor: pointer;
          `}
          onClick={() => {dispatch({actionType: 'TOGGLE_LTR'})}}
        >
          {state.ltr ? model.flow.litre : model.flow.gal} <span>{state.ltr ? 'ltr' : 'gal'}</span>
        </li>
      </ul>
    </div>
  );
};
