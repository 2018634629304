import React from 'react';
import { css, cx } from 'emotion';
import { theme } from 'Components/AppTheme';
import { capitals } from 'Helpers/CommonStyle';

const breadcrumbsHolder = css`
  flex: 1 1 100%;

  > a {
    margin-right: ${theme.space.xs};
    text-decoration: none;
    color: ${theme.color.grey};
  }

  > span {
    margin: 0 ${theme.space.xs};
    color: ${theme.color.grey};
  }
`;

const breadcrumbActive = css`
    color: ${theme.color.white} !important;
    cursor: default !important;
`;

const clickable = css`
    cursor: pointer;
`;

export default ({activeModel, overview, resetModel}) => {
  return (
    <div className={cx(capitals, breadcrumbsHolder)}>
      <span>Aquajet</span><span>/</span>
      <span onClick={() => {resetModel()}} className={cx((!activeModel ? breadcrumbActive : ''), clickable)}>configurator</span>
      {activeModel && (
        <>
          <span>/</span><span onClick={() => {overview && console.log('click')}} className={!overview ? breadcrumbActive : ''}>{activeModel}</span>
        </>
      )}
      {overview && (
        <>
          <span className={breadcrumbActive}>/</span><span className={breadcrumbActive}>overview</span>
        </>
      )}
    </div>
  );
};
