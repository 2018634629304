import React from 'react';
import { css } from 'emotion';
import { theme } from 'Components/AppTheme';

export default () => {
  return (
    <div className={css`
      height: 100%;
      padding: ${theme.space.xl};
      max-width: 720px;

      ${theme.mediaQuery.md} {
        padding: ${theme.space.l} ${theme.space.ms};
        height: auto;
        max-width: none;
      }
    `}>
      <h1>Build your own Ecosilence 3.0 with Aquajet Configurator</h1>
      <p>
        With the Aquajet Configurator you can easily build your own Ecosilence 3.0 and take a closer look at all available options and accessories.
      </p>
      <p>
        Choose from different pressure and flow, add options such as work bench, frost protection and water prefilter – all to meet your specific needs and make every day easier.
      </p>
      <p>
        Start by choosing Pressure or Flow, then it is just a matter of exploring the Ecosilence 3.0 and all available options. Add the options you need to your personal configuration and get your quote. It's that easy!
      </p>
    </div>
  );
};
