import React from 'react';
import { css, cx } from 'emotion';
import { theme } from 'Components/AppTheme';

const checkbox = css`
  position: relative;
  width: 22px;
  height: 22px;
  border: 1px solid ${theme.color.white};
  margin-right: ${theme.space.ms};
`;

const checkboxChecked = css`
  background: ${theme.color.accent};
  border-color: ${theme.color.accent};

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 7px;
    display: block;
    width: 7px;
    height: 14px;
    border: solid ${theme.color.white};
    border-width: 0 2px 2px 0;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const disabledStyle = css`
  opacity: .2;
`;

export default ({defaultChecked, disabled = false}) => {
  return (
    <span className={cx([checkbox, defaultChecked ? checkboxChecked : '', (disabled ? disabledStyle : '')])}></span>
  );
};
