import React, { useState, useContext } from 'react';
import { css, cx } from 'emotion';
import styled from '@emotion/styled';
import { AppContext } from 'Components/AppContext';
import models from 'Data/models';
import { theme } from 'Components/AppTheme';
import HorizontalRadio from 'Blocks/HorizontalRadio';
import { capitals } from 'Helpers/CommonStyle';
import Button from 'Elements/Button';
import utils from '@root/src/utils.js';
import { ReactComponent as IconCarrot } from 'Assets/IconCarrot.svg';

const StyledIconCarrot = styled(IconCarrot)`
  width: 9px;
  height: 16px;
  transform: rotate(180deg);
`;

const ModelSelector = () => {
  const { appState } = useContext(AppContext);
  const {
    setModel,
    unitSystem,
    setUnitSystem
  } = appState;

  const [mode, setMode] = useState(false);

  const unitList = {
    pressure: {
      bar: 'Bar',
      psi: 'PSI',
    },
    flow: {
      litre: 'Litre',
      gal: 'Gal',
    },
  };

  const activeUnit = {
    pressure: unitSystem === 'metric' ? 'bar' : 'psi',
    flow: unitSystem === 'metric' ? 'litre' : 'gal',
  };

  const handleUnits = (value) => {
    if (value === 'psi' || value === 'gal') {
      setUnitSystem('imperial');
    } else {
      setUnitSystem('metric');
    }
  }

  return (
    <>
      <div className={css`
        padding: 0 ${theme.space.ms} ${theme.space.ms};

        ${theme.mediaQuery.md} {
          padding: ${theme.space.ms};
        }
      `}>
        <p className={cx(capitals, css`
          color: ${theme.color.grey};

          ${theme.mediaQuery.md} {
            text-align: center;
          }
        `)}>Choose model</p>
        {mode ? (
          <>
            <p>Enter parameters and choose model to configure.</p>
            <p className={css`
              color: ${theme.color.grey};
              display: flex;
              align-items: center;
              cursor: pointer;
            `} onClick={() => {
              setMode(false);
            }}><StyledIconCarrot className={css`
              margin-right: ${theme.space.s};
            `} /> Back</p>
          </>
        ) : null}
      </div>

      {!mode ? (
        <div className={css`
          padding: 0 ${theme.space.ms} ${theme.space.s};
        `}>
          <Button onClick={() => {
            setMode('pressure');
          }} variant="big" className={css`margin-bottom: ${theme.space.m};`}>Select by Pressure</Button>
          <Button onClick={() => {
            setMode('flow');
          }} variant="big">Select by Flow</Button>
        </div>
      ) : (
        <div className={css`
          padding: 0 ${theme.space.ms} ${theme.space.l};
        `}>
          <div className={css`
            padding: ${theme.space.ms} 0;
            border-top: 1px solid ${theme.color.greyDark};
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}>
            <p className={css`
              font-size: 1.25rem;
              margin-bottom: 0 !important;
            `}>{mode === 'pressure' ? 'Pressure' : 'Flow'}</p>

            <HorizontalRadio values={unitList[mode]} defaultValue={activeUnit[mode]} onChange={(value) => {handleUnits(value)}} />
          </div>

          {Object.keys(models).sort((a, b) => {
            return models[a][mode][activeUnit[mode]] - models[b][mode][activeUnit[mode]];
          }).map((modelKey) => {
            const model = models[modelKey];
            return (
              <div className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:not(:last-child) {
                  margin-bottom: ${theme.space.m};
                }
              `} key={modelKey}>
                <span className={css`
                  font-size: 1.25rem;
                  color: #aaa;
                `}>{utils.formatInteger(model[mode][activeUnit[mode]])} {activeUnit[mode]}</span>
                <Button onClick={() => setModel(modelKey)} variant="small">Select</Button>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ModelSelector;
