import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { theme } from 'Components/AppTheme';
import models from 'Data/models';
import { MediumUp, MediumDown } from 'Helpers/Breakpoints';
import Breadcrumbs from 'Blocks/Breadcrumbs';
import ModelInfo from 'Blocks/ModelInfo';
import concreteUrl from 'Assets/concrete-header-bar.png';
import { ReactComponent as IconConfig } from 'Assets/IconConfig.svg';

const headerBarStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.space.l};
  height: 70px;
  flex: 0 0 70px;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid ${theme.color.greyDark};
  background-image: url(${concreteUrl});
  background-size: cover;
  background-position: center;

  ${theme.mediaQuery.md} {
    padding: 0 ${theme.space.m};
    height: 50px;
    flex: 0 0 50px;
  }

  ${theme.mediaQuery.sd} {
    padding: 0 ${theme.space.sxs};
  }
`;

const StyledIconConfig = styled(IconConfig)`
  width: 22px;
  height: 22px;
  flex: 0 0 auto;
  margin-left: ${theme.space.lm};
  transition: opacity .2s;
  cursor: pointer;
  display: block;

  &:hover {
    opacity: .75;
  }

  ${theme.mediaQuery.md} {
    margin-left: ${theme.space.m};
  }
`;

export default ({model, resetModel, unitSystem}) => {
  return (
    <>
      <MediumUp>
        <div className={headerBarStyle}>
            <Breadcrumbs activeModel={model ? models[model].name : false} resetModel={resetModel} />
          {model && (
            <>
              <ModelInfo model={models[model]} unitSystem={unitSystem} />
              <StyledIconConfig onClick={() => resetModel()}/>
            </>
          )}
        </div>
      </MediumUp>
      <MediumDown>
          {model && (
            <div className={headerBarStyle}>
              <>
                <ModelInfo model={models[model]} unitSystem={unitSystem} />
                <StyledIconConfig onClick={() => resetModel()}/>
              </>
            </div>
          )}
      </MediumDown>
    </>
  );
};
