import React from 'react';
import { AppContextProvider } from 'Components/AppContext';
import { Theme, GlobalStyles, theme } from 'Components/AppTheme';
import { css, cx } from 'emotion';

import Sidebar from 'Parts/Sidebar';
import ContentArea from 'Parts/ContentArea';
import Checkout from 'Parts/Checkout';

const appContainerStyle = css`
  ${theme.mediaQuery.md} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
  }
`;

function App() {
  return (
    <>
      <AppContextProvider>
        <GlobalStyles />
        <Theme>
          <div className={cx('App', appContainerStyle)}>
            <ContentArea />
            <Sidebar />
            <Checkout />
          </div>
        </Theme>
      </AppContextProvider>
    </>
  );
}

export default App;
