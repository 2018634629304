import React from 'react';
import { css, cx } from 'emotion';
import { theme } from 'Components/AppTheme';
import { ReactComponent as IconRemove } from 'Assets/IconRemove.svg';
import { capitals } from 'Helpers/CommonStyle';

const listWrapper = css`
  position: absolute;
  top: 245px;
  width: 100%;
  left: 0;
  height: calc(100% - 299px);
  padding: ${theme.space.ms};
  overflow: auto;
  border-top: 1px solid ${theme.color.greyDark};
`;

const IconRemoveWrapper = css`
  transition: .2s opacity;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  padding: ${theme.space.s};
  cursor: pointer;

  svg {
    width: 8px;
    height: 8px;
  }

  &:hover {
    opacity: .8;
  }
`;

const subMenuItem = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  border-bottom: 1px solid ${theme.color.greyDark};

  &:last-of-type {
    border: none;
  }
`;

export default ({exploreModel, setExploreModel}) => {
  return (
    <div className={listWrapper}>
      {
        Object.keys(exploreModel).map((item, index) => {
          const itemKey = item
          return (
            <>
              <h3 key={index}>{item}</h3>
              {
                Object.keys(exploreModel[item]).map((subItem, index) => {
                  return (
                    <>
                    {(exploreModel[itemKey][subItem].standard || exploreModel[itemKey][subItem].defaultChecked) &&
                      <>
                        {exploreModel[itemKey][subItem].standard ?
                          <div className={subMenuItem}>{subItem}
                            <span className={cx(capitals, css`color: ${theme.color.grey};`)}>Standard</span>
                          </div>
                          :
                          <div className={subMenuItem}>
                            {subItem}
                            <span
                              onClick={() => {setExploreModel(exploreState => {
                                exploreState[itemKey][subItem].defaultChecked = !exploreState[itemKey][subItem].defaultChecked;

                                return {
                                  ...exploreState
                                }
                              })}}
                              className={IconRemoveWrapper}
                            >
                              <IconRemove />
                            </span>
                          </div>
                        }
                      </>
                    }
                    </>
                  )
                })
              }
            </>
          )
        })
      }
    </div>
  )
}
