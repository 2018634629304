import React from 'react';
import Media from 'react-media';
import { theme } from 'Components/AppTheme';

export const MediumDown = ({ children }) => (
  <Media query={
    `(max-width: ${theme.deviceSizes.medium})`
  }>
    {children}
  </Media>
);

export const MediumUp = ({ children }) => (
  <Media query={
    `(min-width: ${theme.deviceSizes.mediumUp})`
  }>
    {children}
  </Media>
);
