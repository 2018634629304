import React, { useContext, useState } from 'react';
import { AppContext } from 'Components/AppContext';
import { css } from 'emotion';
import axios from 'axios';
import ForgeViewer from 'Blocks/ForgeViewer';
import HorizontalRadio from 'Blocks/HorizontalRadio';
import { theme } from 'Components/AppTheme';
import utils from '../../utils';
const viewFunctions = utils.viewFunctions;

const ThreeDeeViewer = () => {

  const { appState } = useContext(AppContext);
  const {
    viewer,
    setViewer,
    setInteriorView,
    allIdsToToggle,
    allIdsToToggleRobot,
    setAllIdsToToggle,
    interiorView,
    idsToSetAlwaysOffInRobot,
    setAllIdsToToggleRobot,
    setIdsToSetAlwaysOffInRobot,
  } = appState;

  const [viewerLoaded, setViewerLoaded] = useState(false);
  const [showRadio, setShowRadio] = useState(false);

  const setView = viewFunctions(viewer, allIdsToToggle, allIdsToToggleRobot, interiorView, idsToSetAlwaysOffInRobot);

  const urn = process.env.REACT_APP_FORGE_URN;

  const handleTokenRequested = (onAccessToken) => {
    console.log('Token requested by the viewer.');
    if (onAccessToken){
      axios.get('/api/accesstoken')
        .then((token) => {
          if (token) {
            onAccessToken(token.data.access_token, token.data.expires_in);
          }
        });
    }
  };

  React.useEffect(() => {
    if (viewerLoaded) {
      if (setView.SetHomeView(setInteriorView)) {
        setShowRadio(true);
        setViewerLoaded(false);
      }
    }
  }, [viewerLoaded, setView, viewer]);

  let allIdsToSetInitialOff= [];
  const CreateListOfIdsToToggleInitialOff = async (providedViewer) => {
    return new Promise(resolve => {
        let listOfNames = ['"70690:1"', '"P1072-341-17 No Side:1"'];  //,  '"60227:1"',  '"P1116-036:1"' ,  '"P1116-030:1"'
        let promises = [];

        listOfNames.forEach((name) => {
            promises.push(new Promise(subresolve => {
              providedViewer.search(name, function (dbIds) {
                    allIdsToSetInitialOff = allIdsToSetInitialOff.concat(dbIds);
                    subresolve();
                }, function (error) {
                    // handle errors here...
                }, ['name']
                )
            }));
        });
        Promise.all(promises).then(() => resolve());
    });
  }

  const handleModelLoaded = (providedViewer, model) => {
    setViewer(providedViewer);
    createListOfIdsToToggle(providedViewer);

    providedViewer.setGhosting(false);
    providedViewer.setLightPreset(11);
    providedViewer.setBackgroundColor(0, 0, 0, 0, 0, 0);

    providedViewer.addEventListener(window.Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, async () => {
      await CreateListOfIdsToToggleInitialOff(providedViewer);

      ToggleInitialsOff(providedViewer);


      providedViewer.impl.visibilityManager.toggleVisibility(2211);
      providedViewer.impl.visibilityManager.toggleVisibility(2481);
      // providedViewer.impl.visibilityManager.toggleVisibility(1531);
      // providedViewer.impl.visibilityManager.toggleVisibility(2598);
      setViewerLoaded(true);
    });

    providedViewer.navigation.toPerspective();
  };

  const handleViewerError = (error) => console.log('Error loading viewer.');
  const handleDocumentError = (viewer, error) => console.log('Error loading a document');
  const handleModelError = (viewer, error) => console.log('Error loading the model.');

  const ShowWalls = (setInteriorView) => {
    if (!allIdsToToggle.length) return;

    setInteriorView(false);

    allIdsToToggle.forEach((id) => {
      viewer.impl.visibilityManager.show(id);
    });
  }

  const HideWalls = (setInteriorView) => {
    if (!allIdsToToggle.length) return;

    setInteriorView(true);

    allIdsToToggle.forEach((id) => {
        viewer.impl.visibilityManager.hide(id);
    });
  }

  const ToggleInitialsOff = (providedViewer) => {
    allIdsToSetInitialOff.forEach((id) => {
      providedViewer.impl.visibilityManager.toggleVisibility(id);
   });
  }

  /********************************************************************** */
  /*** IMPORTED 3-D VIEWER FUNCTIONS ************************************ */
  /********************************************************************** */

  const createListOfIdsToToggle = (providedViewer) => {
    let allIdsToToggleArray = [];
    let allIdsToToggleRobotArray = [];
    let idsToSetAlwaysOffInRobotArray = [];

    const addIdsToToggle = new Promise((resolve, reject) => {
      let listOfNames = ['"P1072-349-1:1"', '"P1072-349-3:1"', '"P1072-349-3:2"', '"P1072-982-12:1"', '"P1072-982-13:1"',
      '"60223:1"', '"60208:1"', '"60207:10"', '"P1072-362:10"', '"60207:11"', '"P1072-362:11"',
      '"60207:12"', '"P1072-362:12"', '"60207:13"', '"P1072-362:13"', '"60211:1"', '"60211:2"',
      '"P1116-091:1"', '"P1116-091:2"', '"P1072-359-4:1"', '"P1072-359-2:4"', '"60244:1"',
      '"63361:1"', '"63361:2"', '"63361:3"', '"63361:4"', '"63361:5"', '"63361:6"', '"63361:7"',
      '"63361:8"', '"63361:9"', '"63361:10"', '"P1072-1110:1"', '"P1072-1111:1"', '"P1072-963:2"',
      '"331478:1"', '"63891:1"', '"002853:1"', '"P1116-303:1"', '"63479:1"', '"63903:1"', '"P1116-070-3:1"',
      '"P1116-093:1"', '"0021005-1:1"', '"P1116-094:1"', '"P1116-119:1"', '"P1116-120:1"', '"P1116-120:1"', '"63361:10"']

      // Container with wall
      listOfNames.push('"P1072-341-17:1"');

      // luckor
      listOfNames.push('"P1116-034:1"');
      listOfNames.push('"331325:1"');
      listOfNames.push('"331325:4"');
      listOfNames.push('"331325:5"');
      listOfNames.push('"331325:6"');
      listOfNames.push('"3314979-2:3"');
      listOfNames.push('"P1116-043:1"');
      listOfNames.push('"P1116-043:2"');
      listOfNames.push('"3314979-2:4"');

      listOfNames.push('"63943:1"');

      let allNames = listOfNames.length
      let nameCount = 0;

      listOfNames.forEach((name) => {
        providedViewer.search(name, (dbIds) => {
          allIdsToToggleArray = allIdsToToggleArray.concat(dbIds);

          nameCount++
          if (nameCount === allNames) resolve();
        }, (error) => {console.log(error)}, ['name'])
      })
    })

    addIdsToToggle.then(() => {
      setAllIdsToToggle(allIdsToToggleArray);
    });

    const addIdsToToggleRobot = new Promise((resolve, reject) => {
      let listOfNames = ['"70690:1"'];

      let allNames = listOfNames.length
      let nameCount = 0;

      listOfNames.forEach((name) => {
        providedViewer.search(name, function(dbIds){
          allIdsToToggleRobotArray = allIdsToToggleRobotArray.concat(dbIds);

          nameCount++
          if (nameCount === allNames) resolve();
        }, (error) => {console.log(error)}, ['name'])
      })
    })

    addIdsToToggleRobot.then(() => {
      setAllIdsToToggleRobot(allIdsToToggleRobotArray);
    });

    const idsToSetAlwaysOffInRobot = new Promise((resolve, reject) => {
      let listOfNames = ['"35255:1"', '"002803-1:1"', '"35313:1"', '"35316:1"'];

      let allNames = listOfNames.length
      let nameCount = 0

      listOfNames.forEach((name) => {
        providedViewer.search(name, function(dbIds){
          idsToSetAlwaysOffInRobotArray = idsToSetAlwaysOffInRobotArray.concat(dbIds);

          nameCount++
          if (nameCount === allNames) resolve();
        }, (error) => {console.log(error)}, ['name'])
      })
    });

    idsToSetAlwaysOffInRobot.then(() => {
      setIdsToSetAlwaysOffInRobot(idsToSetAlwaysOffInRobotArray);
    });
  }

  /********************************************************************** */
  /*** END ************************************************************** */
  /********************************************************************** */

  return (
    <div className={css`
      height: calc(100% - 70px);
      position: relative;

      ${theme.mediaQuery.md} {
        height: 100vw;
      }

      .ForgeViewer{
        width:100%;
        height:100%;
      }

      .ForgeViewer .viewer{
        position:relative;
        width:100%;
        height:100%;
      }

      .ForgeViewer .scrim{
        position:relative;
        width: 100%;
        height:100%;
        z-index: 1000;
        background-color:#ededed;
        color: #95a5a6;
        display:flex;
        align-items: center;
        justify-content: center;
      }

      .ForgeViewer .scrim .message{
        display:flex;
        flex-direction: column;
        align-items: center;
      }

      .ForgeViewer .scrim .message svg{
        margin-bottom:10px;
        width: 50px;
        height: 50px;
      }
    `}>
      {urn && process.env.REACT_APP_3DVIEWER !== 'disable' && (
        <>
          <ForgeViewer
          version="7.*"
          urn={urn}
          headless={true}
          onViewerError={handleViewerError}
          onTokenRequest={handleTokenRequested}
          onDocumentError={handleDocumentError}
          onModelLoad={handleModelLoaded}
          onModelError={handleModelError}
          />
          {showRadio && (
            <HorizontalRadio className={css`
              position: absolute;
              top: ${theme.space.ms};
              right: ${theme.space.l};
              z-index: 10;

              ${theme.mediaQuery.md} {
                top: ${theme.space.sxs};
                right: ${theme.space.sxs};
              }
            `} values={{
              exterior: 'exterior',
              interior: 'interior',
            }} onChange={(radio) => {
              if (!allIdsToToggle.length) return;

              if (radio === 'interior') {
                HideWalls(setInteriorView)
              }
              if (radio === 'exterior') {
                ShowWalls(setInteriorView);
              }
            }}
            defaultValue={interiorView ? 'interior' : 'exterior'} />
          )}
        </>
      )}
    </div>
  );
};

export default ThreeDeeViewer;
