import React, { useContext } from 'react';
import { AppContext } from 'Components/AppContext';
import { css, cx } from 'emotion';
import { theme } from 'Components/AppTheme';
import { ReactComponent as AquajetLogo } from 'Assets/aquajet-logo.svg';
import concreteUrl from 'Assets/concrete-sidebar.png';
import ModelSelector from 'Blocks/ModelSelector';
import ConfigureExplore from 'Blocks/ConfigureExplore';
import { MediumUp, MediumDown } from 'Helpers/Breakpoints';

const sidebarCommonPadding = css`
  padding: ${theme.space.ms};
`;

const logoStyle = css`
  width: 152px;
  height: 19px;
  cursor: pointer;

  .logo-path {
    fill: ${theme.color.brand.main};
  }
`;

const sidebarStyle = css`
  width: ${theme.size.sidebarWidth};
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 1px solid ${theme.color.greyDark};
  background-image: url(${concreteUrl});
  background-size: cover;
  background-position: center;

  ${theme.mediaQuery.md} {
    position: static;
    width: 100%;
    height: auto;
    flex: 0 0 auto;
    border: none;

    &.configure {
      padding-bottom: 42px;
    }
  }
`;

export default () => {
  const { appState } = useContext(AppContext);
  const {
    model,
    setModel,
  } = appState

  return (
    <div className={cx([sidebarStyle, model ? 'configure' : ''])}>
      <MediumUp>
        <div className={sidebarCommonPadding}>
          <a href="https://www.aquajet.se/">
            <AquajetLogo className={logoStyle} />
          </a>
        </div>
      </MediumUp>
      {model ? <ConfigureExplore setModel={setModel} /> : (
        <ModelSelector />
      )}
      <MediumDown>
        <div className={cx(sidebarCommonPadding, css`
          text-align: center;
        `)}>
          <AquajetLogo className={logoStyle} />
        </div>
      </MediumDown>
    </div>
  );
};
