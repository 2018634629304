import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'Components/AppContext';
import styled from '@emotion/styled';
import { theme } from 'Components/AppTheme';
import { capitals } from 'Helpers/CommonStyle';

const RadioItem = styled('span')`
  cursor: pointer;
  transition: color .1s;

  &:hover {
    color: #fff;
  }

  &:not(:last-child) {
    position: relative;
    margin-right: ${theme.space.m};

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0.1em;
      right: -${theme.space.s};
      width: 1px;
      height: 1em;
      background: ${theme.color.grey};
    }
  }

  ${({ currentValue }) => currentValue === true ? `
    color: #fff;
  ` : `
    color: ${theme.color.grey};
  `}
`;

const HorizontalRadio = ({
  onChange = () => {},
  defaultValue,
  values = {},
  ...props
}) => {
  const { appState } = useContext(AppContext);
  const {
    interiorView,
  } = appState;

  const [value, setValue] = useState(defaultValue && values[defaultValue] ? defaultValue : Object.keys(values)[0]);

  const changeValue = (itemValue) => {
    setValue(itemValue);
    onChange(itemValue);
  };

  useEffect(() => {
    if (defaultValue && values[defaultValue]) {
      setValue(defaultValue)
    }
  }, [interiorView]);

  return (
    <div {...props}>
    {Object.keys(values).map((itemValue, index) => {
      return (
        <RadioItem onClick={() => changeValue(itemValue)} className={capitals} currentValue={itemValue === value} key={`${itemValue}-${index}`}>{values[itemValue]}</RadioItem>
      );
    })}
    </div>
  );
};

export default HorizontalRadio;
