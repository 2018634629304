module.exports = [
  {
    title: 'Name',
    name: 'name',
    placeholder: 'Enter your name',
    type: 'text',
    required: true,
    autofocus: true,
  },
  {
    title: 'Company',
    name: 'company',
    placeholder: 'Company name',
    type: 'text',
    required: true,
  },
  {
    title: 'E-mail address',
    name: 'emailaddress',
    placeholder: 'Enter your e-mail address',
    type: 'email',
    required: true,
  },
  {
    title: 'Phone',
    name: 'phone',
    placeholder: 'Enter your phone number',
    type: 'tel',
    required: true,
  },
  {
    title: 'Message',
    name: 'message',
    placeholder: 'Anything else?',
    type: 'textarea',
  },
];
