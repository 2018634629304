import React, { useState } from 'react';
import { css } from 'emotion';
import { theme } from 'Components/AppTheme';

const fieldStyle = css`
  &:not(:last-of-type) {
    margin-bottom: ${theme.space.l};
  }

  label {
    text-transform: uppercase;
    font-size: .85rem;
    letter-spacing: 0.05rem;
    display: block;
    line-height: 1.25rem;
    margin-bottom: ${theme.space.s};
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea {
    display: block;
    width: 100%;
    border: 1px solid #fff;
    appearance: none;
    font-family: ${theme.typography.family.body};
    font-size: 1rem;
    padding: ${theme.space.m};
    background: transparent;
    color: #fff;
    max-width: 100%;
    outline: none;
    border-radius: 0;

    &::placeholder {
      color: ${theme.color.grey};
    }
  }

  textarea {
    height: 120px;
  }
`;

const ValueElem = ({
  type,
  value,
  ...props
}) => {
  return type === 'textarea' ? (
    <textarea defaultValue={value} {...props}></textarea>
  ) : (
    <input type={type} value={value} {...props} />
  );
};

const Field = ({
  title = '',
  type = 'text',
  placeholder = '',
  required = false,
  name = '',
  autofocus,
  onChange,
  validationError,
  ...props
}) => {
  const [value, setValue] = useState('');

  const onValueChange = (e) => {
    const value = e.target ? e.target.value : e;
    setValue(value);

    onChange && onChange(name, value);
  };

  return (
    <div className={fieldStyle}>
      <label>{title} {required ? '*' : <span className={css`color: ${theme.color.grey};`}>(Optional)</span>}</label>
      {validationError ? <p className={css`
        color: ${theme.color.accent};
        font-size: .85rem;
        line-height: 1.25rem;
        margin-bottom: ${theme.space.s} !important;
      `}>{validationError[0]}</p> : null}
      <ValueElem
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onValueChange}
        required={required ? 'required' : ''}
        autoFocus={autofocus ? 'autofocus' : false}
        value={value}
      />
    </div>
  );
};

export default Field;
