import React, { useContext, useState, useEffect } from 'react';
import { css } from 'emotion';
import axios from 'axios';
import { theme } from 'Components/AppTheme';
import { AppContext } from 'Components/AppContext';
import Button from 'Elements/Button';
import { ReactComponent as ArrowIcon } from 'Assets/Arrow.svg';
import CheckoutPane from 'Blocks/CheckoutPane';
import OverviewList from 'Blocks/OverviewList';
import ModelInfo from 'Blocks/ModelInfo';
import Field from 'Elements/Field';
import models from 'Data/models';
import utils from '@root/src/utils.js';
import formFields from 'Data/quoteFormData.js';
import { ReactComponent as AquajetLogo } from 'Assets/aquajet-logo.svg';

const checkoutStyle = css`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20;

  ${theme.mediaQuery.md} {
    position: fixed;
    width: 100%;
  }
`;

const checkoutOverlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 25;
  background: rgba(0, 0, 0, .75);
`;

const modelOverviewStyle = css`
  border-left: 4px solid ${theme.color.accent};
  padding: ${theme.space.s} ${theme.space.m};

  h2 {
    margin-bottom: ${theme.space.m} !important;
  }
`;

const ModelOverview = ({
  model,
  unitSystem,
}) => {
  return (
    <div className={modelOverviewStyle}>
      <h2>{models[model].name}</h2>
      <ModelInfo model={models[model]} unitSystem={unitSystem} />
    </div>
  );
};

const Fields = ({
  fieldErrors = [],
  onFormUpdate,
  setFormValues,
}) => {

  useEffect(() => {
    return () => {
      setFormValues({});
    };
  }, [setFormValues]);

  return (
    <>
      {formFields.map((field, index) => {
        return <Field
          title={field.title}
          placeholder={field.placeholder}
          required={field.required}
          autofocus={field.autofocus}
          onChange={onFormUpdate}
          type={field.type}
          validationError={fieldErrors[field.name]}
          name={field.name}
          key={index}
        />;
      })}
      {(Object.keys(fieldErrors).length !== 0) ? (
        <p className={css`
          color: ${theme.color.accent};
          margin-top: ${theme.space.ms};
        `}>One or more fields have an error. Please check and try again.</p>
      ) : null}
      <div className={css`
        font-size: .85rem;

        a {
          color: #fff;
        }
      `}>
        This form is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </>
  );
};

export default () => {
  const { appState } = useContext(AppContext);
  const {
    model,
    unitSystem,
    exploreModel,
    setExploreModel,
    setInfo
  } = appState;

  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [checkoutStep, setCheckoutStep] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false);
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);

  const [sendStatus, setSendStatus] = useState({
    heading: 'Sending quote request...',
    content: '',
    status: 'pending',
  });

  const onFormUpdate = (name, value) => {
    const newValues = {
      ...formValues,
      [name]: value,
    };

    setFormValues(newValues);
  };

  const closeCheckout = () => {
    setSendStatus({
      heading: 'Sending quote request...',
      content: '',
      status: 'pending',
    });
    setCheckoutStep(0);
    setCheckoutOpen(false);
  };

  const toggleCheckoutOpen = () => {
    setCheckoutOpen(!checkoutOpen);
    setInfo({
      key: false,
      info: ''
    })
  };

  const buildConfigData = () => {
    const config = {};

    const addConfigItem = (category, item) => {
      if (config[category]) {
        config[category].push(item);
      } else {
        config[category] = [item];
      }
    };

    Object.keys(exploreModel).forEach((configCategory) => {
      const categoryItems = exploreModel[configCategory];

      Object.keys(categoryItems).forEach((configItemName) => {
        const configItemValue = categoryItems[configItemName];

        if (configItemValue.defaultChecked) {
          addConfigItem(configCategory, configItemName);
        }
      });
    });

    return config;
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.addEventListener('load', () => {
      setReCaptchaLoaded(true);
    });
    document.body.appendChild(script);
  }, []);

  return model ? (
    <>
      <div className={checkoutStyle}>
        <Button onClick={toggleCheckoutOpen} variant="big filled arrowIcon" className={css`
          width: 230px !important;

          ${theme.mediaQuery.md} {
            width: 100% !important;
          }
        `}>
          Overview
          <ArrowIcon />
        </Button>
      </div>
      {checkoutOpen ? (
        <div className={checkoutOverlay}>
          {checkoutStep === 0 ? (
            <CheckoutPane
            heading="Your Configuration"
            button={{
              text: 'Get your quote',
              onClick: () => {
                setCheckoutStep(1);
              },
            }}
            closeCallback={closeCheckout}
            >
              <ModelOverview model={model} unitSystem={unitSystem} />
              <Button className={css`
                margin: 20px 0;
                display: inline-block;
              `} onClick={() => {
                setDownloadButtonDisabled(true);

                window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'download_overview' })
                  .then((token) => {
                    axios.post('/api/overview-pdf', {
                      config: buildConfigData(),
                      selectedModel: models[model],
                      token,
                    })
                      .then((response) => {
                        const a = document.createElement('a');
                        a.href= `data:application/octet-stream;base64,${response.data}`;
                        a.target = '_blank';
                        a.download = `${models[model].name}.pdf`;
                        a.click();
                        setDownloadButtonDisabled(false);
                      })
                      .catch((err) => {
                        console.log(err.response);
                      });
                  });

              }} variant="small rounded grey filled" disabled={downloadButtonDisabled}>{downloadButtonDisabled ? 'Downloading...' : 'Download Overview (PDF)'}</Button>
              <OverviewList model={model} exploreModel={exploreModel} setExploreModel={setExploreModel} />
            </CheckoutPane>

          ) : (checkoutStep === 1 ? (
            <CheckoutPane
            heading="Contact Details"
            button={{
              text: 'Send',
              disabled: !reCaptchaLoaded,
              onClick: () => {
                const config = buildConfigData();
                const validationErrors = utils.validateFormData(formFields, formValues);

                const selectedModel = model ? [
                  models[model].name,
                  models[model].pressure.bar + ' bar',
                  models[model].flow.litre + ' litre',
                  models[model].force.n + ' N',
                  models[model].motor.hp + ' hp',
                ] : [];

                if (Object.keys(validationErrors).length === 0) {
                  setCheckoutStep(2);

                  window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'form' })
                    .then((token) => {
                      axios.post('/api/quote-request', {
                        formData: formValues,
                        selectedModel,
                        config,
                        token,
                      })
                        .then((response) => {
                          setSendStatus({
                            heading: 'Message sent',
                            content: 'Thank you for your quote request. We will get back to you as soon as possible.',
                            status: 'success',
                          });
                        })
                        .catch((error) => {
                          setSendStatus({
                            heading: 'Message failed',
                            content: (error.response && error.response.data.errorMessage) || 'Something went wrong. Sorry!',
                            status: 'error',
                          });
                        });
                    });
                }

                setFieldErrors(validationErrors);
              },
            }}
            closeCallback={closeCheckout}
            >
              <Fields fieldErrors={fieldErrors} onFormUpdate={onFormUpdate} setFormValues={setFormValues} />
            </CheckoutPane>

          ) : (checkoutStep === 2 ? (
            <CheckoutPane
            heading={sendStatus.heading}
            button={{
              text: 'Close',
              onClick: closeCheckout,
              disabled: sendStatus.status === 'pending',
            }}
            closeCallback={closeCheckout}
            >
              <p>{sendStatus.content}</p>
              {sendStatus.status === 'success' ? (
                <AquajetLogo className={css`
                  width: 152px;
                  height: 19px;

                  .logo-path {
                    fill: ${theme.color.brand.main};
                  }
                `} />
              ) : null}
            </CheckoutPane>
          ) : null))}
        </div>
      ) : null}
    </>
  ) : null;
};
