import styled from '@emotion/styled';
import { theme } from 'Components/AppTheme';

const Button = styled('button')`
  display: inline-block;
  appearance: none;
  font-family: ${theme.typography.family.body};
  border: 1px solid ${theme.color.accent};
  user-select: none;
  background: transparent;
  color: #fff;
  font-size: 1rem;
  padding: ${theme.space.m} ${theme.space.ms};
  cursor: pointer;
  transition:
    border-color .2s,
    background-color .2s;

  &:hover {
    background: ${theme.color.accent};
  }

  &:focus {
    background-color: ${theme.color.accent};
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${theme.color.grey} !important;
    border-color: ${theme.color.grey} !important;
    color: #bbb;

    &:hover,
    &:active,
    &:focus {
      background-color: ${theme.color.grey} !important;
    }
  }

  ${({ variant }) => variant && variant.split(' ').indexOf('filled') !== -1 ? `
    background: ${theme.color.accent};

    &:hover {
      background: ${theme.color.accentDark};
      border-color: ${theme.color.accentDark};
    }
  ` : null}

  ${({ variant }) => variant && variant.split(' ').indexOf('grey') !== -1 ? `
    background: ${theme.color.greyDark};
    border-color: ${theme.color.greyDark};

    &:hover {
      background: ${theme.color.accent};
      border-color: ${theme.color.accent};
    }
  ` : null}

  ${({ variant }) => variant && variant.split(' ').indexOf('arrowIcon') !== -1 ? `
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 30px;
      height: 17px;
      margin-left: ${theme.space.m};

      g {
        stroke: #fff;
      }
    }
  ` : null}

  ${({ variant }) => variant && variant.split(' ').indexOf('big') !== -1 ? `
    width: 100%;
  ` : null}

  ${({ variant }) => variant && variant.split(' ').indexOf('small') !== -1 ? `
    font-size: 0.85rem;
    padding: ${theme.space.xs} ${theme.space.ms};
  ` : null}

  ${({ variant }) => variant && variant.split(' ').indexOf('rounded') !== -1 ? `
    border-radius: 2rem;
    padding: ${theme.space.s} ${theme.space.ms};
  ` : null}
`;

export default Button;
