export default {
  EcoSilence_700_2550: {
    name: "EcoSilence 700",
    pressure: {
      bar: 2550,
      psi: 36984,
    },
    flow: {
      litre: 100,
      gal: 26,
    },
    force: {
      n: 1179,
    },
    motor: {
      hp: 700,
      kw: 515,
    },
  },

  EcoSilence_700_1630: {
    name: "EcoSilence 700",
    pressure: {
      bar: 1600,
      psi: 23200,
    },
    flow: {
      litre: 166,
      gal: 43,
    },
    force: {
      n: 1564,
    },
    motor: {
      hp: 700,
      kw: 515,
    },
  },

  EcoSilence_700_1290: {
    name: "EcoSilence 700",
    pressure: {
      bar: 1290,
      psi: 18710,
    },
    flow: {
      litre: 212,
      gal: 56,
    },
    force: {
      n: 1777,
    },
    motor: {
      hp: 700,
      kw: 515,
    },
  },

  EcoSilence_700_1040: {
    name: "EcoSilence 700",
    pressure: {
      bar: 1040,
      psi: 15080,
    },
    flow: {
      litre: 262,
      gal: 69,
    },
    force: {
      n: 1972,
    },
    motor: {
      hp: 700,
      kw: 515,
    },
  },

  EcoSilence_500_1050: {
    name: "EcoSilence 500",
    pressure: {
      bar: 1050,
      psi: 15228,
    },
    flow: {
      litre: 183,
      gal: 48,
    },
    force: {
      n: 1384,
    },
    motor: {
      hp: 500,
      kw: 405,
    },
  },

  EcoSilence_500_2500: {
    name: "EcoSilence 500",
    pressure: {
      bar: 2600,
      psi: 37710,
    },
    flow: {
      litre: 70,
      gal: 19,
    },
    force: {
      n: 922,
    },
    motor: {
      hp: 500,
      kw: 405,
    },
  },

  EcoSilence_400_1100: {
    name: "EcoSilence 400",
    pressure: {
      bar: 1100,
      psi: 15954,
    },
    flow: {
      litre: 145,
      gal: 38,
    },
    force: {
      n: 1122,
    },
    motor: {
      hp: 400,
      kw: 315,
    },
  },

  EcoSilence_400_1030: {
    name: "EcoSilence 400",
    pressure: {
      bar: 1030,
      psi: 14938,
    },
    flow: {
      litre: 152,
      gal: 40,
    },
    force: {
      n: 1139,
    },
    motor: {
      hp: 400,
      kw: 315,
    },
  },

  EcoSilence_400_1250: {
    name: "EcoSilence 400",
    pressure: {
      bar: 1300,
      psi: 18855,
    },
    flow: {
      litre: 126,
      gal: 33,
    },
    force: {
      n: 1238,
    },
    motor: {
      hp: 400,
      kw: 315,
    },
  },

  EcoSilence_400_2500: {
    name: "EcoSilence 400",
    pressure: {
      bar: 2600,
      psi: 37710,
    },
    flow: {
      litre: 62,
      gal: 16,
    },
    force: {
      n: 700,
    },
    motor: {
      hp: 400,
      kw: 315,
    },
  },
};
