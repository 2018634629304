module.exports = {
  'Exterior': {
    'Standard 20-foot container size': {
      standard: true,
      info: `<p>The 20-foot container, with ISO approved twistlock openings at the corners, provides many benefits. As a world-wide standard size, it is easier to transport while also requiring less space on crowded worksites. Thanks to the new Ecosilence skid’s state-of-the-art design, there is still ample space for a work bench, tools, hose storage and an Aqua Cutter 710 robot. In addition, the compact container provides secure storage and protection from the elements for your equipment.</p>`,
      view: 'SetCamera8',
    },
    'Fork-pockets': {
      standard: true,
      info: `<p>For easier handling, we’ve added fork pockets to the container so that it can be moved by a forklift.</p>`
    },
    'Sound absorbing design': {
      standard: true,
      info: `<p>The container is optimized for sound insulation. It has a new type of high effective sound absorbing cassettes. With no roof hatches and only doors in the back and one on the side, the noise leakage is kept to a minimum. Thanks to the new technology of closed and heat regulated engine room, the new cooling system driven with electric fans that draw in and let out air through sound absorbing air vent grit, the noise level is kept at a minimum.</p>`,
      view: 'SetCamera6'
    },
    'Roll on/off frame': {
      addonStart: true,
      info: `<p>The Roll on/off frame eliminates the need for an additional vehicle. Simply place the container on the ground for easy access. This also enables the unit to be moved with vehicles other than a crane or truck.</p>`,
      defaultChecked: false,
      view: 'SetCamera12',
    },
    'Air vent protection': {
      defaultChecked: false,
      info: `<p>Grate protection is mounted on the Roll on/off frame to shield air vents on the front of the container during loading and unloading.</p><p>This option is only available with the Roll on/off frame.</p>`,
      view: 'SetCamera12',
      dependencies: ['Roll on/off frame'],
    },
    'Outside fuel and AdBlue filler caps': {
      defaultChecked: false,
      info: `<p>As standard, the fuel and AdBlue filler caps are located on the inside of the Ecosilence 3.0, next to the door. Having them on the exterior, with a lockable hatch as protection, is available as an option.</p>`,
      view: 'SetCamera18'
    },
    'Electric kit 400/230 VAC': {
      defaultChecked: false,
      info: `
        <p>Optional electrical kit for European standard.</p>
        <p>- 32A inlet and outlet outside container</p>
        <p>- 32A inlet/outlet and 1-phase outlets inside container</p>
        <p>- Battery charger</p>
        <p>- 5kW electric heater</p>
      `,
      view: 'SetCamera15'
    },
  },
  'Interior': {
    'Skid mounted engine and pump' : {
      standard: true,
      info: `<p>The engine and pump in the new Ecosilence 3.0 are built as a single, skid-mounted system that is located in the container. This skid system, with engine and pump as a unit,  is more complete than its predecessors and includes integrated water tank and filters. The new engine and pump combination provides improved water routing, making it both smarter and more efficient. Additionally, all drainage pipes are gathered to one location with only one drain water outlet for easy access and maintenance.</p>`,
      view: 'SetCamera2'
    },
    'Easy access maintenance and service' : {
      standard: true,
      info: `<p>The clean, open design of the Ecosilence 3.0 provides easy access for routine maintenance operations such as checking fluid levels or oil and filter changes. This means less downtime for maintenance and more time getting the job done. If necessary, the wall section on the side can be removed to access the complete skid-mounted unit.</p>`
    },
    'Liquid-to-air cooling system' : {
      standard: true,
      info: `
        <p>The Ecosilence 3.0 has a completely new cooling system design. It uses engine coolant to transfer heat from the engine to the cooler module, which has a large cooler area. Through sound-absorbing air vents,  cold air is drawn in by electric speed-controlled fans. The space behind the coolers pressurizes as the air comes in, cools the heat exchanger area, and transports the heat out through the front-end air vents.</p>
        <p>The noise level is kept to a minimum thanks to the electric speed-controlled fans with low air-flow velocity, the extremely large cooler area and the low pressure drop. Power consumption is also reduced, which saves as much as 20 liters of diesel a day.</p>
        <p>The new liquid-to-air cooling system is totally separated from the skid compartment, creating a partition wall in the container. This ensures the skid compartment is closed and heat regulated, preventing dust or debris from entering.</p>
      `,
      view: 'SetCamera5'
    },
    'Robot inside Ecosilence 3.0' : {
      standard: true,
      info: `<p>Although the Ecosilence 3.0 is smaller than its predecessors, both the 410 and 710 Aqua Cutter robots still fit inside. All of your equipment is ready to transport in one compact container, eliminating the need for an extra trailer. The container also provides secure storage for the robot on the jobsite.</p>`,
      view: 'SetCamera9'
    },
    'Work bench with tools' : {
      addonStart: true,
      defaultChecked: false,
      info: `<p>A built-in workbench with vice and toolboard is another available option. It includes all the most common tools needed for service, as well as storage space for spare parts.</p>`,
      view: 'SetCamera16'
    },
    'Storage' : {
      defaultChecked: false,
      info: `<p>Storage features include: 10 movable, heavy hooks for storing hoses, cables and clothing; brackets for radio control, both for Ecosilence and robot.</p>`,
      view: 'SetCamera17',
    },
    'Driving ramps for Aqua Cutter' : {
      defaultChecked: false,
      info: `<p>Aluminum ramps to load an Aqua Cutter 710 or 410 robot into the container. Includes fixing brackets on the back door and security straps to secure the robot to the floor brackets. </p>`,
      view: 'SetCamera9',
    },
    'Air kit with airgun' : {
      defaultChecked: false,
      info: `<p>Optional air compressor unit, 230V, for the workbench.</p><p>Only available with "Exterior > Electric kit 400/230 VAC".</p>`,
      view: 'SetCamera25',
      dependencies: ['Electric kit 400/230 VAC'],
    },
    'Comfort wall with sliding doors' : {
      defaultChecked: false,
      info: `<p>An optional wall with sliding doors to separate the pump and engine compartment from the workspace that is both sound and heat isolated.</p>`
    },
    'Diesel heater' : {
      defaultChecked: false,
      info: `<p>A diesel heater is available for installation inside the container. This system includes a timer and keeps the container warm without the need for an external power supply.</p>`,
      view: 'SetCamera25'
    },
    'Frost protection kit': {
      defaultChecked: false,
      info: `<p>As an option, you can add a frost protection kit with an anti-freeze and anti-corrosion liquid tank and electric pump. This protects and preserves the pump in low temperatures when not in operation. It also prevents corrosion and dried gaskets. The recirculating system recycles the glycol every time it’s used. Better for the environment, better for you!</p>`,
      view: 'SetCamera14'
    },
  },
  'Water': {
    'Pressure and flow': {
      standard: true,
      info: `<p>The Ecosilence 3.0 can be configured up to 3000 bar UHP (Ultra high pressure) and almost any flow.</p>`,
      view: 'SetCamera4'
    },
    'Water inlet and outlet': {
      standard: true,
      info: `
        <p>The water inlet and outlet remain at the front of the container, protected by a sliding hatch.</p>
        <p>All drain points on the unit are routed to one outlet. Nothing drains under the container.</p>
      `,
      view: 'SetCamera7',
    },
    'Proportional inlet valve': {
      standard: true,
      info: `<p>This new feature creates constant and controlled water flow into the unit. Constant pressure in the inlet hose prevents it from brushing the ground when pressure fluctuates. This significantly reduces wear on the hose. It also makes overall operation more efficient while prolonging the life of the equipment.</p>`,
      view: 'SetCamera11'
    },
    'Lifting device for pump head': {
      addonStart: true,
      defaultChecked: false,
      info: `<p>The lifting device is a small crane for lifting the pump head of the high-pressure pump for service. This device is attached to the pump with two screws and can either be removed when service is done or be stationary-mounted until next service.</p>`,
      view: 'SetCamera13'
    },
    'USA equipped': {
      defaultChecked: false,
      info: `<p>High-pressure and water supply connections that fit the U.S. standard are available as an option. </p>`,
      view: 'SetCamera7'
    },
    'Water prefilter': {
      defaultChecked: false,
      info: `<p>An optional water filter at the inlet line is available to catch any particles before they enter the water tank. This also provides additional safety, since there will be two serial connected filters. This option is recommended for all UHP-pumps used with untreated water.</p>`,
      view: 'SetCamera19'
    },
    'Inlet pressure reducing valve, max 40 bar': {
      defaultChecked: false,
      info: `<p>Placed on the water inlet coupling, this optional feature reduces inlet water pressure from a maximum of 40 bar down to 4-6 bar, allowing for proper function of the inlet valve. This option is recommended for situations when the connection to the water supply exceeds maximum inlet pressure to the unit. </p>`,
      view: 'SetCamera7'
    },
    'Complete dosing system': {
      defaultChecked: false,
      info: `<p>A complete dosing system for measuring and correcting water conductivity is available as an option. If the water conductivity is too low (water too hard) the piston seal assembly can be destroyed. This applies to high pressure pumps with tungsten carbide plunger seal sets. The dosing system adds a sodium carbonate solution to the water to keep conductivity at a proper level.</p>`,
      view: 'SetCamera24',
    },
    'Kit Dump valve': {
      defaultChecked: false,
      info: `<p>When flow from the high-pressure pump is too high for the tool, a dump valve can be used to route some of the high-pressure water back to the water tank.</p>`,
      view: 'SetCamera24'
    },
    'Kit Dump gun': {
      defaultChecked: false,
      info: `<p>A mechanical dump gun is available for high-pressure cleaning to dump the water at shut down. It doesn’t need an electric remote cable and can be used for all brands of pump units.</p>`
    },
    'Filter differential pressure monitoring': {
      defaultChecked: false,
      info: `<p>With this optional function, you get a warning if any water filter needs to be replaced. </p>`,
      view: 'SetCamera19'
    },
  },
  'Sound of Silence': {
    'Exhaust silencers': {
      addonStart: true,
      defaultChecked: false,
      info: `<p>The Ecosilence 3.0 features a specially developed exhaust silencer designed to eliminate low frequency engine sound. It is heat protected and mounted inside the container to make the operation very quiet.</p>`,
      view: 'SetCamera10'
    },
  },
  'Control System': {
    'REVO 3.0 control system': {
      standard: true,
      info: `<p>The new REVO control system is equipped with the latest technology to focus on both efficiency and the environment. The system features all the functions you need to control and monitor the Ecosilence 3.0, and it’s safe and user friendly. Parameters are easily set on the graphic color display, with operator instructions available in multiple languages. See all important information in real-time or view the history, such as alarm lists and trip meters of fuel consumption. The system also automatically tracks service schedules, keeping downtime to a minimum.</p>`,
      view: 'SetCamera3'
    },
    'Revo Remote': {
      standard: true,
      info: `
        <p>The Revo remote system is standard on the new Ecosilence. It can either be left in its place on the unit, or detached and used up to 300 meters away with extension cables. It allows you to take full control of all processes wherever you are operating your Aqua Cutter. The Revo remote also has a connection point for the radio receiver in case the radio signal cannot reach the operator directly from the Ecosilence unit. It can be used, for example, when you are working inside a structure while the Ecosilence remains outside.</p>
        <p>The Revo Remote has built-in logics, allowing operators to connect several different high-pressure tools, including high-pressure guns, foot pedals and other pressure actuators. This makes it ideal for industrial cleaning contractors looking to use different tools for different worksites. The tools are connected directly to the Revo Remote Logic with a standard 7-pole connector.</p>
        <p>Compatible high-pressure tools:</p>
        <p>- All Aquajet tools, both with cable and radio controls.</p>
        <p>- Tools from the most common manufacturers.</p>
        <p>- Tools with 2xNO switches.</p>
      `,
      view: 'SetCamera3'
    },
    'Radio remote': {
      standard: true,
      info: `<p>The Radio remote control is also standard on the Ecosilence 3.0. The radio remote has a small color display with the most common functions. The radio remote receiver is mounted inside the container with an antenna outside. If the worksite is far away or inside a building, the radio receiver can be brought closer to the worksite and connected to the Revo remote.</p>`,
      view: 'SetCamera21'
    },
    'Auto main power system activation': {
      standard: true,
      info: `<p>When ignition is switched on, the main power system is also switched on.</p>`
    },
    'Extension cables': {
      defaultChecked: false,
      info: `
        <p>Aquajet cables are specially designed for Hydrodemolition equipment and come with 7-pole, heavy-duty Aquajet connectors. This option includes a cable drum with lifting handle and brake and comes in following cable lengths: 25m, 50m, 75m, 100m</p>
        <p>Aquajet special extension cables are also available without the cable drum in following lengths: 3m (for use between pressure control handle and an extra emergency stop box or other short distances), 10m,15m, 25m.</p>
      `,
      view: 'SetCamera23'
    },
    'Extra receiver for connected controls': {
      defaultChecked: false,
      info: `<p>An extra receiver can be added to the robot’s radio remote control, allowing both Ecosilence and Aqua Cutter to be connected to each other and to the operator via the radio remote control. Simple and with no need for extra cables or devices. Provides full control over both units in an easy and safe way. Through the remote control you can turn the high-pressure ON/OFF and emergency stop both units,  as well as see and change the settings of pressure and engine rpm.</p>`,
      view: 'SetCamera21'
    },
  },
  'Environment': {
    'Engine': {
      standard: true,
      info: `<p>The engine has the latest environmentally friendly emission technology, making it more effective and fuel efficient than ever before. As a bonus, it offers a flat torque curve, meaning it can run with full pressure at lower rpm. The Revo control system is fully integrated with the engine, optimizing engine life and service intervals.</p>`
    },
    'Auto Start/Stop': {
      standard: true,
      info: `<p>The Ecosilence 3.0 comes with the latest automatic start/stop technology, meaning the system automatically shuts down and restarts the engine to reduce the amount of time the engine spend idling, thereby providing a more environmentally friendly operation with reduced fuel consumption and emissions. This function also increases operational safety and service intervals while prolonging engine life. In addition, this function typically saves as much as 20-25 liters of fuel a day.</p>`,
      view: 'SetCamera3'
    },
    'Auto Shutdown': {
      standard: true,
      info: `
        <p>The main power system is fully automatic and includes a number of built-in features:</p>
        <p>- Auto shutdown at low battery level: System monitors the battery level and will automatically shut down when the level gets critical. </p>
        <p>- Auto shutdown timeout: System has an adjustable timeout so that it can automatically shut down the main power after a certain time.</p>
        <p>- Auto shutdown at engine boot down: The main power is also automatically kept on until the engine has finished its shutdown processes.</p>
        <p>- Auto shutdown at lights off: For service purposes, the ignition can be switched off and the ignition key removed, but the system keeps the power on as long as the container’s light is on. This is a safety mode where all functions are disabled except for the container light. When the container light is switched off, the main power is also switched off.</p>
      `
    },
    'Quick start (liquid-to-air cooling system)': {
      standard: true,
      info: `<p>With the electric fan system on the Ecosilence 3.0, the engine quickly reaches operating temperature, reducing engine wear and downtime. As a bonus, the electric fan provides quieter, more efficient running compared to a traditional belt-driven fan. This can easily save as much as 20 liters of diesel a day.</p>`,
      view: 'SetCamera5'
    },
  },
  'Safety': {
    'Flashing status light': {
      standard: true,
      info: `<p>A flashing status light is attached to the Ecosilence 3.0 on both the outside and inside. This safety feature communicates both information and warnings by changing the flash frequency and color.</p>`,
      view: 'SetCamera22'
    },
    'Spark arrestor': {
      addonStart: true,
      defaultChecked: false,
      info: `<p>Optional spark extinguisher for ex-classified areas. Spark arrestor is mounted on top of the container and can be removed for transport.</p>`,
      view: 'SetCamera2'
    },
    'Foot pedal Revo': {
      defaultChecked: false,
      info: `<p>The foot pedal Revo is used when the operator needs to have both hands free. The pressure is activated when pedal is pushed and de-activated when pedal is released. It comes with an emergency stop box and includes a 10m cable. Not for robot operation.</p>`,
      view: 'SetCamera23'
    },
    'Shut down valve': {
      defaultChecked: false,
      info: `<p>Shuts off the intake air when the engine is switched off in the event of flammable gases.</p>`,
      view: 'SetCamera2'
    },
    'Emergency stop box Revo': {
      defaultChecked: false,
      info: `<p>The emergency stop box Revo is used as an extra emergency stop. If needed, multiple emergency stop boxes can be placed along the remote cable. The kit includes an emergency stop and 1m cable.</p>`,
      view: 'SetCamera23'
    }
  },
};
