import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { css, cx } from 'emotion';
import { theme } from 'Components/AppTheme';
import { capitals } from 'Helpers/CommonStyle';

import { AppContext } from 'Components/AppContext';
import utils from '../../utils'

import Checkbox from 'Elements/Checkbox';
import Text from 'Elements/Text';

import { ReactComponent as IconCarrot } from 'Assets/IconCarrot.svg';
import { ReactComponent as IconInfo } from 'Assets/IconInfo.svg';

const viewFunctions = utils.viewFunctions;

const StyledIconCarrot = styled(IconCarrot)`
  width: 9px;
  height: 16px;
`;

const StyledIconCarrot180 = styled(IconCarrot)`
  width: 9px;
  height: 16px;
  transform: rotate(180deg);
`;

const StyledIconInfo = styled(IconInfo)`
  transition: .2s stroke;
  position: absolute;
  right: ${theme.space.ms};
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  stroke: ${theme.color.white};
  z-index: 9;
  cursor: pointer;

  &:hover {
    stroke: ${theme.color.accent};
  }
`;

const menu = css`
  position: relative;
  height: calc(100% - 120px);

  ${theme.mediaQuery.md} {
    height: auto;
  }
`

const menuListPanel = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${theme.mediaQuery.md} {
    position: static;
    height: auto;
  }
`;

const menuListItem = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.space.ms};
  border-bottom: 1px solid ${theme.color.greyDark};
  width: 100%;
  cursor: pointer;

  &:after {
    transition: .2s opacity;
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: white;
    opacity: 0;
  }

  &:hover:after {
    opacity: .2;
  }
`;

const subMenuListPanel = css`
  display: flex;
  flex-direction: column;
`;

const subMenuListWrapper = css`
  position: absolute;
  top: 78px;
  height: calc(100% - 78px);
  width: 100%;
  overflow: auto;

  ${theme.mediaQuery.md} {
    position: static;
    height: auto;
  }
`;

const subMenuListHeader = css`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${theme.color.greyDark};
  padding: 0 ${theme.space.ms};
  height: 78px;
  width: 100%;
  cursor: pointer;

  &:after {
    transition: .2s opacity;
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: white;
    opacity: 0;
  }

  &:hover:after {
    opacity: .2;
  }
`;

const subMenuListItem = css`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${theme.color.greyDark};
  width: 100%;

  &:not(.standard) {
    cursor: pointer;
  }

  &:after {
    transition: .2s opacity;
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: white;
    opacity: 0;
  }

  &:not(.standard):hover:after {
    opacity: .2;
  }

  > span {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: ${theme.space.ms};
    z-index: 9;
  }
`;

const infoPanel = css`
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: ${theme.space.ms};
  z-index: 15;

  ${theme.mediaQuery.md} {
    position: relative;
  }

  > div {
    padding-bottom: ${theme.space.l};
  }
`;

const disabledStyle = css`
  cursor: default !important;

  &::after {
    display: none;
  }
`;

const handleInfoModal = (setInfo, showInfo, key, info) => {
  if (showInfo) {
    setInfo({
      key: key,
      info: info ? info : '<h2>404</h2><p>No information for this for this setting found ...</p>'
    })
  } else {
    setInfo({
      key: false,
      info: ''
    })
  }
};

const SubMenuListItem = ({
  item,
  index,
  list,
  subMenuItem,
  exploreModel,
  setExploreModel,
  setInfo,
  setView,
  activeCategory,
}) => {
  const dependencies = list[item].dependencies;
  const [enabled, setEnabled] = useState(!dependencies);

  const toggleChecked = () => {
    setExploreModel(exploreState => {
      exploreState[subMenuItem][item].defaultChecked = !exploreState[subMenuItem][item].defaultChecked;

      if (!exploreState[subMenuItem][item].defaultChecked) {
        Object.keys(exploreState).forEach((categoryName) => {
          const category = exploreState[categoryName];

          Object.keys(category).forEach((confItemName) => {
            const confItem = category[confItemName];

            if (confItem.dependencies && confItem.dependencies.indexOf(item) > -1) {
              confItem.defaultChecked = false;
            }
          });
        });
      }

      return {
        ...exploreState
      }
    });
  };

  if (dependencies) {
    let depCheckCount = 0;

    dependencies.forEach((dependency) => {
      Object.keys(exploreModel).forEach((categoryName) => {
        const category = exploreModel[categoryName];

        if (category[dependency] && category[dependency].defaultChecked) {
          depCheckCount += 1;
        }
      });
    });

    if (depCheckCount === dependencies.length) {
      !enabled && setEnabled(true);
    } else {
      enabled && setEnabled(false);
    }
  }

  return (
    <div className={cx([subMenuListItem, exploreModel[subMenuItem][item].standard ? 'standard' : '', (!enabled ? disabledStyle : '')])}>
      <span
        onClick={() => {
          if (enabled) {
            toggleChecked();
          }
        }}
        key={`sub-${index}`}
      >
        {!exploreModel[subMenuItem][item].standard && <Checkbox disabled={!enabled} defaultChecked={exploreModel[subMenuItem][item].defaultChecked} />}
        <p className={css`max-width: 168px; margin-bottom: 0 !important;`}>{item}</p>
      </span>
      <StyledIconInfo onClick={() => {
        handleInfoModal(setInfo, true, item, exploreModel[subMenuItem][item].info);
        exploreModel[subMenuItem][item].view && setView[exploreModel[subMenuItem][item].view]();
      }} />
    </div>
  )
};

const SubMenuList = ({
  list,
  subMenuItem,
  exploreModel,
  setExploreModel,
  setInfo,
  setView,
  activeCategory,
}) => {
  useEffect(() => {
    setExploreModel(exploreModel);
  }, [exploreModel, setExploreModel]);

  return (
    <>
      {
        Object.keys(list).map((item, index) => {
          const props = {
            item,
            index,
            list,
            subMenuItem,
            exploreModel,
            setExploreModel,
            setInfo,
            setView,
            activeCategory,
          };
          return (
            <React.Fragment key={index}>
              {exploreModel[subMenuItem][item].addonStart && (
                <p className={cx(capitals, css`
                color: ${theme.color.grey};
                margin: ${theme.space.ms} 0 ${theme.space.s} ${theme.space.ms} !important;
                `)}
                  >
                  Add Ons
                </p>
              )}
              <SubMenuListItem {...props} />
            </React.Fragment>
          );
        })
      }
    </>
  )
}

export default () => {
  const { appState } = useContext(AppContext);
  const {
    exploreModel,
    setExploreModel,
    info,
    setInfo,
    viewer,
    interiorView,
    setInteriorView,
    allIdsToToggle,
    allIdsToToggleRobot,
    idsToSetAlwaysOffInRobot,
  } = appState;

  const [subMenu, setSubMenu] = useState(false);

  const setView = viewFunctions(viewer, allIdsToToggle, allIdsToToggleRobot, interiorView, idsToSetAlwaysOffInRobot, setInteriorView);

  return (
    <div className={menu}>
      {!subMenu && (
        <div className={menuListPanel}>
          {
            Object.keys(exploreModel).map((item, index) => {
              return (
                <div className={menuListItem} key={index} onClick={() => {setSubMenu(item)}} >{item}<StyledIconCarrot /></div>
              )
            })
          }
        </div>
      )}

      {subMenu && (
        <div className={subMenuListPanel}>
          {info.key ?
          <div className={subMenuListHeader} onClick={() => {handleInfoModal(setInfo, false); setView.SetHomeView(setInteriorView)}}><StyledIconCarrot180 /> <span className={css`margin-left: ${theme.space.l};`}>{info.key ? info.key : subMenu}</span></div>
          :
          <div className={subMenuListHeader} onClick={() => {setSubMenu(false)}}><StyledIconCarrot180 /> <span className={css`margin-left: ${theme.space.l};`}>{info.key ? info.key : subMenu}</span></div>
          }
          <div className={subMenuListWrapper}>
            {!info.key && (
              <>
                <p className={cx(capitals, css`
                  color: ${theme.color.grey};
                  margin: ${theme.space.ms} 0 ${theme.space.s} ${theme.space.ms} !important;
                  `)}
                >
                  Standard
                </p>

                <SubMenuList activeCategory={subMenu} list={exploreModel[subMenu]} subMenuItem={subMenu} exploreModel={exploreModel} setExploreModel={setExploreModel} setInfo={setInfo} setView={setView} />
              </>
            )}
            {info.key &&
              <div className={infoPanel}>
                <Text text={info.info} />
              </div>
            }
          </div>
        </div>
      )}
    </div>
  )
};
