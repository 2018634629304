import React, { createContext, useState } from 'react';
import configure from '../data/configure'

const AppContext = createContext();

const AppContextProvider = React.memo((props) => {
  const [viewer, setViewer] = useState(null);
  const [interiorView, setInteriorView] = useState(false);
  const [allIdsToToggle, setAllIdsToToggle] = useState([]);
  const [allIdsToToggleRobot, setAllIdsToToggleRobot] = useState([]);
  const [idsToSetAlwaysOffInRobot, setIdsToSetAlwaysOffInRobot] = useState([]);
  const [model, setModel] = useState(false);
  const [unitSystem, setUnitSystem] = useState('metric');
  const [info, setInfo] = useState({
    key: false,
    info: '',
  });

  // Configure DATA (Can be exracted to the data-map)
  const [exploreModel, setExploreModel] = useState(configure)

  const appState = {
    viewer,
    setViewer,
    interiorView,
    setInteriorView,
    allIdsToToggle,
    setAllIdsToToggle,
    allIdsToToggleRobot,
    setAllIdsToToggleRobot,
    idsToSetAlwaysOffInRobot,
    setIdsToSetAlwaysOffInRobot,
    model,
    setModel,
    unitSystem,
    setUnitSystem,
    exploreModel,
    setExploreModel,
    info,
    setInfo
  };

  return <AppContext.Provider value={{ appState }}>{props.children}</AppContext.Provider>;
});

export { AppContext, AppContextProvider };
